/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #00b3e460;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #00b3e457;
}

.messageHeader {
  color: #00b4e4;
  font-size: 40px;
  font-family: "Barlow";
  font-weight: 700;
  letter-spacing: 0px;
  /* line-height: 56px; */
}

a {
  color: #202a44;
  text-decoration: none;
  border-bottom: 0.5px solid #202a44;
}

.checkoutStepsContainer {
  position: relative;
  height: 45px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  background-color: white;
  border: 1px solid #e1edf0;
  border-bottom: none;
  border-top: none;
  padding: 15px 0 0;
}

.checkoutSteps {
  height: 24px;
}

/* INTRO PAGE */

.introContainer {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #e8f4f9;
  border: 1px solid #e1edf0;
  padding: 50px 30px 5px;
  color: #202a44;
}

.introHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  margin: auto;
  margin-left: 113px;
}

.introSubheader {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  margin: 18px 0;
  max-width: 700px;
}

.hiThere {
  margin-top: 20px;
  text-transform: uppercase;
}

.introConsents {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  margin-bottom: 100px;
}

.introFooter {
  margin-top: 18px;
  font-size: 18px;
}

/* HEADER SECTION */

.pageHeader {
  background-color: white;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row-reverse;
  /* align-items: left; */
  flex-shrink: 0;
}

.pageTitle {
  background: url("../Assets/ilMedslogo.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 190px;
  height: 100%;
}

.progressBar {
  height: 4px;
  width: 100%;
  background-color: #e1edf0;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

  flex-shrink: 0;
}

.progressLevel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* background-color: #47CDFF; */
  background-color: #1ba69c;
  overflow: hidden;
  z-index: 20;
}

/* MAIN FORM */

.pageContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-family: "Barlow";
  font-weight: 500;
  overflow: hidden;
  color: #202a44;
}

.contentContainer {
  position: relative;
  width: 100vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 0 120px; */
  overflow: hidden;
  background-color: #e8f4f9;
}

.contentContainer.lastPageContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
}

.prevFormCont {
  position: relative;
  display: flex;
  border-top: none;
  border-bottom: none;
  padding: 50px 40px 5px 40px;
  box-sizing: border-box;
  height: 100%;
}

.previousButtonHolder {
  width: 48px;
  margin-right: 25px;
}

.previousButton {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid white;
  margin: 10px 15px 15px 0;
  flex-shrink: 0;
  cursor: pointer;
}

.previousButton:hover > .vectorArrow {
  border-color: #43d7ff;
}

.formStep {
  margin-top: 25px;
  margin-bottom: 12px;
  font-size: 16px;
}

.formAndContinue {
  position: relative;
  width: 100%;
  max-width: 700px;
  height: calc(100vh - 160px);
  /* overflow-y: scroll; */
  padding-bottom: 50px;
}

.formAndContinue.lastPage {
  width: 100%;
  max-width: unset;
  padding-right: 10%;
}

.formStyle {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.formStyleWithContinue {
  max-height: calc(100% - 70px);
}

.formQuestionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
}

.formSubRow {
  margin: 5px 5px;
}

.formField {
  position: relative;
  color: #00b4e4;
  font-size: 36px;
  letter-spacing: 0;
  margin-bottom: 40px;
  line-height: normal;
  font-weight: 400;
}

.formFieldQuestionBulletPoints {
  position: relative;
  width: 100%;
  height: max-content;
  font-size: 20px;
}

.formFieldSmall {
  color: #637a82;
  font-size: 15px;
  letter-spacing: 0;
  margin-bottom: 5px;
  list-style: none;
}

.formInput {
  height: 80px;
  width: 100%;
  min-width: 301px;
  max-width: 400px;
  width: 100%;
  /* border: 1px solid #D1D8DC; */
  border: 2px solid #e1edf0;
  /* border-radius: 4px; */
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
}

.formInput:active,
.formInput:focus {
  outline: thin dotted #47cdff;
}

.formInput.numberInput {
  margin-bottom: 20px;
}

.formTextBox {
  box-sizing: border-box;
  width: 86%;
  height: 130px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 16px;
  margin: 30px 2px 40px 2px;
  font-family: "Barlow";
  resize: none;
}

.formTextBox:active,
.formTextBox:focus {
  outline: thin dotted #47cdff;
}

.formRadioLine {
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  border: none;
  border-radius: 50px;
  color: #202a44;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: white;
  cursor: pointer;
  transition: border 0.5s;
  border: 1.5px solid white;
}

.formRadioLine:hover {
  border: 1.5px solid #c1c1c1;
}

.formRadioLineSelected {
  border: 1.5px solid #00b4e4;
}

.formRadioLineSelected > .formRadioCircle {
  border: 1px solid #00b4e4;
}

.pharmacySelect .pharmacyRadioLineSelected > .formRadioCircle {
  border: 1px solid #707070;
  background-color: #00b4e4;
}

.formRadioCircle {
  border: 1px solid #707070;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.formRadioInnerCircle {
  background-color: #00b4e4;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  /* padding: 2px; */
}

.formRadioText {
  font-size: 18px;
  color: #202a44;
  text-align: left;
  font-family: "Barlow";
  font-weight: 500;
}

.formDatePicker {
  box-sizing: border-box;
}

.react-datepicker__input-container > input:focus {
  border: 1px solid #00b4e4 !important;
  outline: none;
}

.react-datepicker__triangle::before {
  left: -30px !important;
}

.react-datepicker__triangle::after {
  left: -30px !important;
}

.formSelector {
  box-sizing: border-box;
}

.formSelector:focus-within {
  border: 1px solid #00b4e4 !important;
}

.formSelector > label {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.formSelector > label > select {
  position: relative;
  width: 100%;
  border: none;
  cursor: pointer;
}

.formSelector > label > select:focus {
  border: none;
  outline: none;
}

.formSectionText {
  font-size: 20px;
  margin-bottom: 15px;
}

.orderButton {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  max-width: 400px;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 26px;
  background-color: #00b4e4;
  color: #ffffff;
  font-size: 20px;
  margin: 5px 0 5px;
  padding: 0px 0px 5px 30px;
  cursor: pointer;
  font-family: "Barlow";
  font-weight: 500;
  transition: background-color 0.5s;
}

.getStartedButton {
  min-width: 220px;
}

.orderButton::after {
  content: "";
  position: absolute;
  right: 25px;
  top: 50%;
  width: 12px;
  height: 12px;
  background: url("../Assets/arrow-right.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(0, -50%);
}

.orderButton:hover {
  background-color: #43d7ff;
}

.orderButton:disabled {
  background-color: #00b4e44d;
  cursor: not-allowed;
}

.orderButton:active,
.orderButton:focus {
  border: none;
  box-shadow: none;
  outline: thin dotted #47cdff;
}

.warningText {
  color: #202a44;
  font-size: 14px;
  margin-top: 15px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}

.vectorArrow {
  position: relative;
  left: 2.5px;
  border: solid #00b4e4;
  border-width: 0 2.4px 2.4px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: border-color 0.5s;
}

/*
.vectorArrow::after {
  content: "";
  background-color: #00B4E4;
  width: 15px;
  height: 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: rotate(45deg) translate(1px, 4.5px);
  -webkit-transform: rotate(45deg) translate(1px, 4.5px);
} */

.radioContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkBoxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "NHaasGroteskTXPro-55Rg";
  font-size: 20px;
}

.checkBoxLine {
  display: flex;
  align-items: center;
  min-height: 50px;
  max-width: 650px;
  border: 1.5px solid transparent;
  border-radius: 12px;
  color: #002f40;
  padding: 10px 20px 12px 20px;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.checkBoxLineContainer {
  margin-bottom: 10px;
}

.checkBoxLine:hover {
  border: 1.5px solid #c1c1c1;
}

.checkBoxLineSelected {
  border: 1.5px solid #00b4e4;
}

input[type="checkbox"],
input[type="checkbox"]:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  background-color: transparent;
}

.checkBox {
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid #707070;
  border-radius: 5px;
  margin: 0 15px 0 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

input:checked::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid #00b4e4;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.introConsents > input:active {
  border: 2px solid #34495e;
}

.checkBoxSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border: 1px solid #00b4e4;
  /* background-color: white; */
}

.checkShape {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: #00b4e4;
  border-radius: 3px;
  top: 1px;
}

.checkBoxText {
  font-size: 18px;
  color: #202a44;
  text-align: left;
  font-family: "Barlow";
  font-weight: 500;
}

.bpContainer {
  /* height: 200px; */
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.bpInput {
  width: 172px;
  height: 56px;
  border: 2px solid #e1edf0;
  border-radius: 12px;
  padding: 16px;
  font-size: 20px;
}

.bpSlash {
  background-color: #e1edf0;
  width: 3px;
  height: 44px;
  transform: rotate(30deg);
  margin: 30px;
}

.scrollList {
  overflow-y: auto;
  max-height: 200px;
  text-align: start;
  margin: 5px 0;
}

/* CHECKOUT PAGE */

.checkoutContainer,
.demographicContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
}

.checkoutContainer {
  justify-content: flex-start;
}

.checkoutFormImage {
  position: fixed;
  width: 100vw;
  height: 40vw;
  max-height: 550px;
  /* background: url("../Assets/MaskGroup1631.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  right: -60px;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
}

.checkoutForm {
  height: 100%;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  padding-right: 30px;
}

.checkoutForm > .messageHeader {
  text-transform: uppercase;
}

.dataSecureText {
  margin-top: 20px;
  font-size: 16px;
}

.demographicSectionLabel {
  align-self: flex-start;
  text-transform: uppercase;
  margin: 60px 0 20px 0;
  font-weight: 600;
}

.consultationPrice {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 10px;
}

.checkoutSubRow {
  display: flex;
  flex-direction: column;
  height: 280px;
  width: 100%;
  background-color: white;
  border: 2px solid white;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
}

.checkoutButton {
  min-width: 230px;
  max-width: 290px;
}

.selectPharmButton {
  position: relative;
  width: 100%;
  height: 45px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: white;
  text-align: left;
  transition: border 0.5s;
}

.selectPharmButton::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  width: 10px;
  height: 10px;
  background: url("../Assets/arrow-right.svg");
  filter: invert(1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(0, -50%);
}

.selectPharmButton:hover {
  cursor: pointer;
  border: 1.5px solid #c1c1c1;
}

.uploadLogo {
  width: 70px;
  height: 80px;
  background: url("../Assets/upload-cloud.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.fileUpload {
  height: 40px;
  width: 212px;
  text-align: center;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileUpload::after {
  display: none;
}

.fileDescriptionText {
  font-size: 16px;
  color: #202a44;
  margin-top: 5px;
}

.uploadedImg {
  position: relative;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.displayNone {
  display: none;
}

.checkoutError {
  color: red;
  font-size: 18px;
}

/* SUCCESS PAGE */

.successPage {
  padding: 0px;
}

.successContainer {
  position: relative;
  background: url("../Assets/Rectangulo-103.svg");
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 0;
  height: 100%;
  width: 100%;
  border-top: 1px solid #e1edf0;
}

.successLeftColumn {
  width: 60%;
  display: flex;
  flex-direction: row;
}

.dotsPatternImg {
  width: 80px;
  background: url("../Assets/Enmascarar-grupo2.svg");
  background-repeat: no-repeat;
  background-position: center 160px;
  flex-shrink: 0;
}

.successContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 50px;
  padding-top: 50px;
}

#successPreviousButton {
  background-color: white;
  margin-bottom: 80px;
}

.successRightColumn {
  width: 40%;
  flex-shrink: 0;
}

.successHeader {
  color: #002f40;
  font-size: 47px;
  font-family: "NeueHaasGroteskDisp Pro Md";
  letter-spacing: 0px;
  line-height: 48px;
  margin-bottom: 15px;
}

.successBody {
  font-size: 20px;
  color: #002f40;
  font-family: "Merriweather-Light";
  margin-bottom: 30px;
}

.successFooter {
  font-size: 12px;
  color: #637a82;
  font-family: "NHaasGroteskTXPro-55Rg";
}

.successFooter > a > button {
  margin-top: 50px;
}

.ccContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  min-height: 200px;
}

.ccDetails {
  position: relative;
  width: 60%;
}

.cardDetailsRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.expiryDateHolder {
  width: 70%;
}

.expiryDateInput {
  width: 40%;
}

.expiryDateInput:focus {
  outline: thin dotted #47cdff;
}

.expiryDateInput:active {
  outline: thin dotted #47cdff;
}

.cvcHolder {
  width: 30%;
}

.cvcInput {
  width: 100%;
}

.cvcInput:focus {
  outline: thin dotted #47cdff;
}

.cvcInput:active {
  outline: thin dotted #47cdff;
}

.checkoutFooter {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}

/* demographic page */

.demographicContainer .demographicSubRow {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.demographicContainer .innerSubRow {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
  justify-content: flex-start;
  box-sizing: border-box;
}

.demographicContainer .formFieldSmall {
  position: relative;
  color: #202a44;
  font-size: 16px;
  letter-spacing: 0;
  margin-bottom: 5px;
  box-sizing: border-box;
  width: fit-content;
}

.demographicContainer .checkoutField {
  margin: 15px 0 4px 0;
}

.demographicContainer .checkoutField::after {
  content: "*";
  position: absolute;
  right: -6px;
  color: #00b4e4;
}

.demographicContainer .noAsterisk::after {
  content: "";
}

.demographicContainer .checkoutInput {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.demographicContainer input {
  height: 45px;
  margin-top: 5px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}
.demographicContainer select {
  width: 100%;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  width: 100%;
  cursor: inherit;
  line-height: inherit;
}

.demographicContainer input:active {
  outline: thin dotted #47cdff;
}

/* .demographicContainer input::placeholder {
  color: white;
} */

.demographicContainer .checkoutInput:active,
.demographicContainer .checkoutInput:focus {
  outline: thin dotted #47cdff;
}

.demographicContainer .checkoutInputShort {
  width: 40%;
  border: 1px solid white;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}
.demographicContainer .checkoutInputShort.state,
.demographicContainer .checkoutInputShort.zip {
  width: 100%;
  border: 1px solid white;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.demographicContainer .checkoutInputShort.state {
  background-color: white;
  height: 45px;
  margin-top: 5px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
}

.checkoutInputShort.state label {
  position: relative;
  width: 100%;
}
.checkoutInputShort.state label:after {
  content: ">";
  font: 11px "Consolas", monospace;
  color: #aaa;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 2px;
  top: 2px;
  padding: 0 0 2px;
  border-bottom: 1px solid #ddd;
  position: absolute;
  pointer-events: none;
}

#state-select:invalid {
  color: #757575;
}

.expirySlash {
  margin: 0 5px;
  font-size: 20px;
  font-weight: 400;
}

.zipContainer {
  padding-left: 5px;
}

.demographicContainer .stateAndZip {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 5px 0;
  justify-content: space-between;
  box-sizing: border-box;
}

.demographicContainer .checkoutInputShort:active,
.demographicContainer .checkoutInputShort:focus {
  outline: thin dotted #47cdff;
}

.demographicContainer .checkoutInputMini {
  width: 30%;
  border: 1px solid white;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.demographicContainer .checkoutInputMini:active,
.demographicContainer .checkoutInputMini:focus {
  outline: thin dotted #47cdff;
}

.demographicContainer .dobInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.demographicContainer .checkoutError {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.demographicContainer .ageError {
  font-size: 12px;
  letter-spacing: 0;
  margin: 0 auto;
  color: #e50100;
  width: fit-content;
}

.demographicContainer .spacer {
  width: 10px;
}

.stripePower {
  height: 20px;
  margin: 5px auto 0 0;
}

/* ERROR STYLES */

.errorMessageHeader {
  margin-top: 15px;
  color: #b82714;
  font-size: 16px;
  font-weight: 500;
}

.messageBody {
  font-size: 16px;
  color: #202a44;
  margin-top: 2px;
  font-weight: 500;
}

.messageFooter {
  font-size: 12px;
  color: #637a82;
  font-family: "NHaasGroteskTXPro-55Rg";
  padding-top: 20px;
}

.pageFooter {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #202a44;
  min-width: 369px;
  max-width: unset;
  border-top: 1px solid #e1edf0;
}

.nowrap {
  white-space: nowrap;
}

.modal-content {
  padding: 0 30px;
  border: none;
  border-radius: 5px;
}

.modalCloseButton {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  background-color: transparent;
}

.modalCloseButton > img {
  width: 25px;
  height: 25px;
  /* filter: invert(1) */
}

.modalBody {
  margin: 10px 5px 10px 5px;
}

.modalHeader {
}

#searchPharmInput {
  border: 1px solid #c1c1c1;
  width: 105%;
}

#searchPharmInput:focus {
  border: 1px solid #00b4e4;
  outline: none;
}

/* #searchPharmInput::placeholder {
  color: #b0b0b0 !important;
}

#searchPharmInput::-moz-placeholder {
  color: #b0b0b0 !important;
}

#searchPharmInput:-ms-input-placeholder {
  color: #b0b0b0 !important;
} */

.findPharmButton {
  width: 100px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding-left: 5px;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.findPharmButton > img {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  /* filter: invert(1); */
}

.findPharmButton::after {
  background: none;
}

.pharmResults {
  position: relative;
  width: 100%;
  margin: 40px 0;
  border-top: 1px solid #eaeaea;
  padding: 30px 5px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pharmacyListContainer {
  position: relative;
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
}

.pharmacyListContainer > .formRadioLine {
  max-width: unset;
  text-align: left;
  align-items: unset;
  transition: unset;
  border: none;
  transition: background-color 0.5s;
  border-radius: 3px;
}

.pharmacyListContainer > .formRadioLine:hover {
  border: none;
  background-color: rgb(247, 247, 247);
  border-radius: 3px;
}

.pharmacyListContainer > .formRadioLine > .formRadioCircle {
  margin-top: 4px;
}

.pharmacyStatusText {
  position: relative;
  margin: auto;
  width: max-content;
  color: gray;
}

.loader {
  margin: auto;
  position: relative;
  border: 8px solid #f0f0f0; /* Light grey */
  border-top: 8px solid #00b4e4; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pharmText {
  margin-left: 5px;
}

.pharmSelectedText {
  margin: 5px 5px 10px 5px;
}

.pharmName {
  font-weight: 500;
  font-size: 18px;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.modalCancelButton {
  width: 100px;
  background-color: white;
  border: 1px solid #c1c1c1;
  padding-left: 0;
  text-align: center;
  color: gray;
  transition: all 0.5s;
}

.modalCancelButton:hover {
  background-color: white;
  color: #00b4e4;
  border: 1px solid #00b4e4;
}

.modalCancelButton::after {
  background: none;
}

.modalSaveButton {
  width: 130px;
  /* padding-left: 0; */
  /* text-align: center; */
}

/* -------------------------------- */
.photo.ccContainer {
  min-height: 120px;
  font-size: 18px;
}

.photoIdContainer {
  max-height: 35vh;
}

.photoIdContainer .checkoutFooter {
  margin-top: 10px;
}

.photoImageContainer {
  position: relative;
  max-width: 150px;
  max-height: 150px;
}

.uploadedPhotoDisplay .photoWithCloseButton {
  display: flex;
  align-items: flex-start;
  max-width: 30%;
  padding: 5px;
}

.uploadedPhotoDisplay .closeButton {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  margin-left: 3px;
}

.closeButton svg {
  vertical-align: top;
}

@media screen and (max-width: 800px) {
  .pageContainer {
    height: 100vh;
    width: 100vw;
    /* height: calc(100% - 30px); */
  }

  .introHeader {
    padding-left: 8px;
    padding-right: 8px;
    margin: auto 40px;
  }

  .prevFormCont {
    position: relative;
    display: flex;
    justify-content: initial;
    box-sizing: border-box;
    flex-direction: column;
    border-bottom: 1px solid #e1edf0;
    padding-top: 30px;
  }

  .messageHeader {
    font-size: 34px;
  }

  .errorMessageHeader {
    font-size: 16px;
  }

  /* .messageBody {
    font-size: 14px;
  } */

  .introSubheader {
    font-size: 24px;
  }

  .introFooter {
    font-size: 16px;
  }

  .introContainer {
    width: 100vw;
    height: 100%;
    /* height: calc(100% - 30px); */
    justify-content: flex-start;
    padding: 15px 0 50px 0;
    color: #202a44;
    overflow-y: scroll;
  }

  .introConsents {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .contentContainer {
    position: relative;
    width: 100%;
    /* width: calc(100% - 20px);
    min-width: 369px;
    max-width: 369px; */
    /* margin-bottom: 10px; */
  }

  .contentContainer.lastPageContainer {
    width: 100vw;
    position: relative;
  }

  .userInputContainer {
    width: 100%;
  }

  .formAndContinue.lastPage {
    padding-right: 0;
  }

  .formAndContinue {
    width: 100%;
    flex-grow: 1;
  }

  .formStyle {
    /* width: 90vw; */
    padding: 0;
    box-shadow: none;
  }

  .formField {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .formFieldQuestionBulletPoints {
    font-size: 15px;
  }

  .formInput {
    width: 100%;
    max-width: 323px;
    box-sizing: border-box;
    height: 56px;
    min-width: unset;
  }

  .formTextBox {
    width: 100%;
    margin: 0px 0px 15px 0px;
  }

  .formRadioLine {
    margin-bottom: 10px;
  }

  .checkBoxLine {
    font-size: 14px;
  }

  .checkoutContainer,
  .demographicContainer {
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    flex-direction: column;
    box-shadow: none;
  }

  .demographicSubRow.dobAndAddress,
  .demographicSubRow.emailAndPhone,
  .demographicSubRow.addressRow,
  .demographicSubRow.pharmacyRow {
    flex-direction: column;
  }

  .checkoutForm {
    width: 100%;
    padding-right: 0px;
  }

  .checkoutFormImage {
    display: none;
  }

  .checkoutFormContainer {
    width: inherit;
  }

  .checkoutSubRow {
    flex-direction: column;
  }

  .pageFooter {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: #202a44;
    border-top: 1px solid #e1edf0;
  }

  /* Success Page */

  .successContent {
    justify-content: center;
  }

  .dotsPatternImg {
    display: none;
  }

  .successLeftColumn {
    width: 100%;
    z-index: 1;
  }

  .successContent {
    padding: 0 50px;
  }

  .successHeader {
    font-size: 40px;
  }

  #successPreviousButton {
    display: none;
  }

  .successRightColumn {
    position: absolute;
    left: 25%;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: contain;
    background-position: 0 10px;
  }

  .lastPage .orderButton {
    /* font-size: 16px;
    width: 100%;
    padding: 0px 50px 6px 20px; */
    height: 60px;
  }

  .fileUpload {
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  .photoIdContainer {
    max-height: unset;
  }

  .uploadedPhotoDisplay {
    flex-direction: column;
  }

  /* .uploadedPhotoDisplay .uploadedImg {
    max-height: 500px;
    height: auto;
    width: 100%;
  } */

  .uploadedPhotoDisplay .photoContainer {
    margin-bottom: 5px;
  }

  .photoImageContainer {
    max-width: unset;
    max-height: unset;
  }

  .uploadedPhotoDisplay .photoWithCloseButton {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    padding: 10px;
  }

  .pharmacyRow {
  }
}
